import React from "react";
import { IOverviewPage, IOverviewSection } from "../../domain";
import Grid from "./Grid";
import Header from "./Header";
import { PageDiv, PagesDiv, SectionDiv, SectionsDiv, Logo } from "./Sections.styled";
import { SetPage, NavigationReason } from "./types";
import Color from "../../colors";

interface IProps {
  sections: IOverviewSection[];
  pages: IOverviewPage[];
  currentPage: IOverviewPage;
  setPage: SetPage;
  navigationReason?: NavigationReason;
  onChangePage: (pageId: string) => void;
}

interface IState {
  alreadyLoadedPageIds: string[];
}

enum Direction {
  Left,
  Right,
}

export default class Sections extends React.Component<IProps, IState> {
  state = {
    alreadyLoadedPageIds: [],
  };
  private scrollRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    this.setState({ alreadyLoadedPageIds: [...this.state.alreadyLoadedPageIds, this.props.currentPage._id] });
    this.scrollRef.current?.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.scrollToCurrentPage(false);
  }

  public componentWillUnmount() {
    this.scrollRef.current?.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (!(this.state.alreadyLoadedPageIds as string[]).includes(nextProps.currentPage._id)) {
      this.setState({ alreadyLoadedPageIds: [...this.state.alreadyLoadedPageIds, nextProps.currentPage._id] });
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.currentPage._id !== this.props.currentPage._id &&
      this.props.navigationReason === NavigationReason.Button
    ) {
      this.scrollToCurrentPage(true);
    }
  }

  public render() {
    const languageSwitchTextColor = this.props.currentPage.languageSwitchTextColor || Color.Black;

    const clickCover = () => {
      const el = document.querySelector('#next-arrow');
      el?.parentElement?.setAttribute('data-show', 'true');
      el?.classList.add('nudge');
      setTimeout(()=> {
        el?.classList.remove('nudge');
        el?.parentElement?.removeAttribute('data-show');
      },1500);
    };

    return (
      <SectionsDiv ref={this.scrollRef}>
        {this.props.sections.map((section, sectionIndex) => (
          <SectionDiv onClick={() => { sectionIndex === 0 && clickCover() }} key={section._id}>
            { sectionIndex === 0 && <Logo src="/static_assets/dpg-logo-full.svg" alt="DPG Media" width="848" height="446" /> }
            <Header section={section} hideSectionTitle={sectionIndex === 0} textColor={languageSwitchTextColor} />
            <PagesDiv>
              {section.pages.map((page, pageIndex) => {
                const shouldBeVisible = (this.state.alreadyLoadedPageIds as string[]).includes(page._id);
                return (
                  <PageDiv key={page._id}>
                    <Grid grid={page.grid} sectionColor={section.color} shouldBeVisible={shouldBeVisible} />
                  </PageDiv>
                );
              })}
            </PagesDiv>
          </SectionDiv>
        ))}
      </SectionsDiv>
    );
  }

  private handleScroll = () => {
    const pageId = this.pageIdForCurrentScrollPosition();

    if (this.props.navigationReason === NavigationReason.Button) {
      if (pageId === this.props.currentPage._id) {
        // Navigation was started by pressing a button and the view
        // scrolled to the required position:
        this.props.onChangePage(pageId);
      }
    } else if (pageId && pageId !== this.props.currentPage._id) {
      // Navigation was done by scrolling the view and it has gone
      // far enough to change the page:
      this.props.setPage(pageId, NavigationReason.Scroll);
      this.props.onChangePage(pageId);
    }
  };

  private handleResize = () => {
    this.scrollToCurrentPage(false);
  };

  private scrollToCurrentPage = (animated: boolean) => {
    const pageIndex = this.props.pages.findIndex((page) => page._id === this.props.currentPage._id);
    const scrollWidth = this.scrollRef.current?.scrollWidth;

    if (scrollWidth !== undefined) {
      const scrollPosition = (scrollWidth / this.props.pages.length) * pageIndex;
      this.scrollRef.current?.scroll({ left: scrollPosition, behavior: animated ? "smooth" : "auto" });
    }
  };

  private scrollLeft = 0;
  private pageIdForCurrentScrollPosition = (): string | undefined => {
    const currentScrollLeft = this.scrollRef.current?.scrollLeft;
    const scrollWidth = this.scrollRef.current?.scrollWidth;

    if (currentScrollLeft === undefined || scrollWidth === undefined) {
      console.warn("Could not get scroll information:", { scrollLeft: currentScrollLeft, scrollWidth });
      return;
    }

    const direction = currentScrollLeft > this.scrollLeft ? Direction.Right : Direction.Left;
    this.scrollLeft = currentScrollLeft; // side effect alert!

    const pageIndex =
      direction === Direction.Left
        ? Math.ceil(currentScrollLeft / (scrollWidth+1) * this.props.pages.length)
        : Math.floor(currentScrollLeft / (scrollWidth-1) * this.props.pages.length);

    return this.props.pages[pageIndex]?._id;
  };
}
