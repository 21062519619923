import React from "react";
import styled from "styled-components";
import { breakPoints, BreakPoint, rValue, scaledValue, srValue } from "../../../layout";
import { contentColumnWidths, sidebarColumnWidths, inbetweenColumnWidths } from "../utils";

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const ContainerDiv = styled.div`
  position: relative;
  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    ${srValue("width", sidebarColumnWidths)}
  }
`;

const InnerContentDiv = styled.div`
  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    position: absolute;
    width: 100%;
    ${rValue("transform", [
      `translateX(${scaledValue(contentColumnWidths[0] + inbetweenColumnWidths[0], BreakPoint.S)})`,
      `translateX(${scaledValue(contentColumnWidths[1] + inbetweenColumnWidths[1], BreakPoint.M)})`,
      `translateX(${scaledValue(contentColumnWidths[2] + inbetweenColumnWidths[2], BreakPoint.L)})`,
      `translateX(${scaledValue(contentColumnWidths[3] + inbetweenColumnWidths[3], BreakPoint.XL)})`,
    ])};
  }
`;

export default function SidebarContent(props: IProps) {
  return (
    <ContainerDiv>
      <InnerContentDiv>{props.children}</InnerContentDiv>
    </ContainerDiv>
  );
}
