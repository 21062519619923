import React, { useContext } from "react";
import { IArticleHeader, IColor, Language, IOverviewSection } from "../../domain";
import * as Styled from "./Header.styled";
import LanguageContext from "../../context/LanguageContext";
import HeadingSlice from "../shared/HeadingSlice";

interface IProps {
  onCloseArticle: () => void;
  header: IArticleHeader;
  content: any;
  sectionColor: IColor;
  pageId: string;
  sections: IOverviewSection[];
}

export default function Header(props: IProps) {
  const { header, content, onCloseArticle, pageId, sections } = props;
  const { language } = useContext(LanguageContext);
  let sectionName: string = language === Language.NL ? "Onbekend" : "Unknown";
  const section = sections.find(s => !!s.pages.find(p => p._id === pageId));
  if (section) {
    sectionName = section.title[language] ?? sectionName;
  }

  if (header.isArticleView2) {
    return (
      <Styled.ContainerDiv>
        {header.image && (
          <Styled.WideImageColumnDiv>
            <Styled.BackToPageLink onClick={onCloseArticle}>
              <Styled.ArrowLeft />
              "DPG Media" / {sectionName}
            </Styled.BackToPageLink>
            <Styled.ContentImageDiv header={header}>
              {header.label && (
                <Styled.ImageLabelStyledText
                  text={header.label}
                  color={header.labelColor}
                  font={header.labelFont}
                  size={header.labelSize}
                />
              )}
              <HeadingSlice content={content} />
            </Styled.ContentImageDiv>
          </Styled.WideImageColumnDiv>
        )}
      </Styled.ContainerDiv>
    );
  }

  return (
    <Styled.ContainerDiv>
      <Styled.BackToPageLink onClick={onCloseArticle}>
        <Styled.ArrowLeft />
        {language === "en" ? "DPG Media" : "DPG Media"} / {sectionName}
      </Styled.BackToPageLink>

      {header.image?.src && (
        <Styled.ImageColumnDiv>
          <Styled.ImageDiv header={header} />
        </Styled.ImageColumnDiv>
      )}

      {header.image?.src && (
      <Styled.ContentColumnDiv>
        <Styled.ContentDiv>
          {header.label && (
            <Styled.LabelStyledText text={header.label} font={header.labelFont} size={header.labelSize} />
          )}
          {content && <HeadingSlice content={content} />}
          {header.intro && <Styled.IntroStyledText text={header.intro} />}
        </Styled.ContentDiv>
      </Styled.ContentColumnDiv>
      )}
    </Styled.ContainerDiv>
  );
}
