import styled from "styled-components";
import Color from "../../colors";
import { srValue, breakPoints, BreakPoint } from "../../layout";
import { contentColumnWidths } from "./utils";

export const ContainerDiv = styled.div`
  margin-bottom: 400px;
`;

export const ContentItemsDiv = styled.div`
  color: ${Color.Black};
  margin-left: 1rem;
  margin-right: 1rem;

  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    ${srValue("margin-left", [92, 122, 287, 406])}
    ${srValue("width", contentColumnWidths)};
  }
`;

export const ContentItemDiv = styled.div`
  margin-bottom: 2.4rem;
`;

export const CloseButton = styled.button`
  position: fixed;
  top: 16px;
  left: 16px;
  width: 16px;
  height: 16px;
  z-index: 2;
  background-color: white;
`;
