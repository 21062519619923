import React from "react";
import { IOverviewPage } from "../../domain";
import * as Styled from "./ArrowNavigation.styled";
import { SetPage, NavigationReason } from "./types";

interface IProps {
  pages: IOverviewPage[];
  currentPageIndex: number;
  setPage: SetPage;
}

interface IState {
  isVisible: boolean;
  isHoveringArrow: boolean;
}

export default class ArrowNavigation extends React.Component<IProps, IState> {
  public state: IState = {
    isVisible: true,
    isHoveringArrow: false,
  };

  private visibleTimeout = 0;

  public componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("mousemove", this.handleMouseMove);
    this.handleMouseMove();
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("mousemove", this.handleMouseMove);
    if (this.visibleTimeout) {
      clearTimeout(this.visibleTimeout);
    }
  }

  public render() {
    const { currentPageIndex, pages } = this.props;
    const { isVisible } = this.state;

    return (
      <Styled.ContainerDiv
        isVisible={isVisible}
        onMouseEnter={() => this.setState({ isHoveringArrow: true })}
        onMouseLeave={() => this.setState({ isHoveringArrow: false })}
      >
        <Styled.PreviousArrowButton onClick={this.toPreviousPage} isDisabled={currentPageIndex === 0}>
          <Styled.PreviousArrowSvg $isVisible={isVisible}/>
        </Styled.PreviousArrowButton>

        <Styled.NextArrowButton id="next-arrow" onClick={this.toNextPage} isDisabled={currentPageIndex === pages.length - 1}>
          <Styled.NextArrowSvg $isVisible={isVisible}/>
        </Styled.NextArrowButton>
      </Styled.ContainerDiv>
    );
  }

  private toPreviousPage = () => {
    this.toPage(this.props.pages[this.props.currentPageIndex - 1], this.props);
  };

  private toNextPage = () => {
    this.toPage(this.props.pages[this.props.currentPageIndex + 1], this.props);
  };

  private toPage = (page?: IOverviewPage, props?: any) => {
    if (page) {
      this.props.setPage(page._id, NavigationReason.Button);
    } else {
      console.warn("page is null", page, props);
    }
  };

  private handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowLeft":
        e.preventDefault();
        this.toPreviousPage();
        break;
      case "ArrowRight":
        e.preventDefault();
        this.toNextPage();
        break;
    }
  };

  private handleMouseMove = () => {
    this.setState({ isVisible: true });
    if (this.visibleTimeout) {
      clearTimeout(this.visibleTimeout);
    }
    //@ts-ignore
    this.visibleTimeout = setTimeout(() => {
      if (!this.state.isHoveringArrow) {
        this.setState({ isVisible: false });
      }
    }, 2000);
  };
}
