import { useContext } from "react";
import LanguageContext from "../../../context/LanguageContext";
import { Language } from "../../../domain";
import { IBasicTeaser, VerticalAlignment } from "../../../domain";
import { IContentInset } from "../../../layout";
import { BodyStyledText, ContainerDiv, ImageImg } from "./BasicTeaser.styled";
import HeadingSlice from '../../shared/HeadingSlice';

interface IProps {
  teaser: IBasicTeaser;
  contentInset: IContentInset;
  sectionColor: string;
  shouldBeVisible: boolean;
}

export default function BasicTeaser(props: IProps) {
  const { language } = useContext(LanguageContext);
  const { teaser, sectionColor, shouldBeVisible } = props;
  const imgSrc = shouldBeVisible ? (language === Language.EN && teaser.image_en?.src? teaser.image_en?.src : teaser.image?.src) : "";

  return (
    <ContainerDiv contentInset={props.contentInset}>
      {teaser.image && teaser.imagePosition === VerticalAlignment.Top && (
        <ImageImg src={imgSrc + `&w=400&fit=max`} alt={teaser.image.alt} />
      )}

      {teaser.slices && (
        <HeadingSlice content={
          teaser.slices.find((slice) => slice?.type === 'heading')
        }/>
      )}

      {teaser.body && <BodyStyledText sectionColor={sectionColor} isTeaser={true} text={teaser.body} />}
      {teaser.image && teaser.imagePosition === VerticalAlignment.Bottom && (
        <ImageImg src={imgSrc + `&w=400&fit=max`} alt={teaser.image.alt} />
      )}
    </ContainerDiv>
  );
}
