import styled, { css } from 'styled-components';
import { FontFamily } from '../../../domain';
import Color from '../../../colors';
import { rValue } from '../../../layout';
import { IPrismicText } from '../../../data/utils';
import SidebarContent from './SidebarContent';

interface IProps {
  primary: {
    placement: string,
    variant: Variant,
    author: [{
      text: string,
    }] | null,
    content: IPrismicText,
  },
}

enum Variant {
  ITALIC_THIN = 'italic-thin',
  ITALIC_LIGHT = 'italic-light',
  MEDIUM = 'medium',
  SEMI_BOLD = 'semi-bold',
  BOLD = 'bold'
}

const variantMapping = {
  [Variant.ITALIC_THIN]: css`
    font-family: ${FontFamily.BentonModernOne};
    font-style: italic;
    font-weight: 400;
  `,
  [Variant.ITALIC_LIGHT]: css`
    font-family: ${FontFamily.BentonModernOne}
    font-style: italic;
    font-weight: 400;
    text-transform: uppercase;
  `,
  [Variant.MEDIUM]: css`
    font-family: ${FontFamily.GlyphaLtStd};
    font-weight: 700;
  `,
  [Variant.SEMI_BOLD]: css`
    font-family: ${FontFamily.BentonSans};
    font-weight: 700;
  `,
  [Variant.BOLD]: css`
    font-family: ${FontFamily.BentonSans}
    font-weight: 900;
  `,
};

const Content = styled.blockquote<{ variant: Variant }>`
  color: ${Color.Gray_800};
  ${(props) => variantMapping[props.variant as keyof typeof variantMapping]};
  ${rValue('font-size', ['1.75rem', '2rem', '2.25rem', '2.5rem'])};
`;

const Cite = styled.cite`
  display: block;
  ${rValue('font-size', ['1rem', '1rem', '1rem', '1.125rem'])};
`;

export default function QuoteSlice({
  primary: { placement, variant, author, content }
}: IProps) {
  if (!content.length)
    return null;

  const textContent = content
    .flatMap((fragment) => [fragment.text, <br />])
    .slice(0, -1);

  return (
    <>
      {placement === 'sidebar' ? (
        <SidebarContent>
          <Content variant={variant}>
            { textContent }
            { author && <Cite>{ author[0].text }</Cite> }
          </Content>
        </SidebarContent>
      ) : (
        <Content variant={variant}>
          { textContent }
          { author && <Cite>{ author[0].text }</Cite> }
        </Content>
      )
      }
    </>
  )
}
