import styled from "styled-components";
import Color from "../../../colors";
import { IContentInset, responsiveValue, zeroResponsiveValue } from "../../../layout";
import { teaserInset, bottomGutter, topGutter } from "../layout";

interface IContainerDivProps {
  contentInset: IContentInset;
  isLastInColumn: boolean;
}

export const ContainerDiv = styled.div`
  ${teaserInset}
  ${responsiveValue("padding-bottom", zeroResponsiveValue)};

  &::after {
    content: "";
    display: ${(p: IContainerDivProps) => (p.isLastInColumn ? "none" : "block")};
    bottom: 0;
    height: 1px;
    width: 100%;
    background: ${Color.Gray_200};
    ${responsiveValue("margin-top", topGutter)}
  }
`;

export const ContainerTitle = styled.span`
  color: ${Color.Black};
  ${responsiveValue("margin-bottom", bottomGutter)};
`;
