import React from "react";
import { ILocalizedStyledText, IStyledTextSpan, StyledTextSpanType, StyledTextType } from "../../domain";
import { IProps as IStyledTextProps } from "./StyledText";
import styled from "styled-components";
import { rValue, breakPoints, BreakPoint } from "../../layout";

export type IProps = Omit<IStyledTextProps, "text"> & {
  text: ILocalizedStyledText;
};

const TeaserH1 = styled.h2`
  font-size: 1.875rem;
  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    ${rValue("font-size", ["50px", "52px", "68px", "88px"])}
  }
`;

const TeaserH2 = styled.h2`
  font-size: 1.5rem;
  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    ${rValue("font-size", ["36px", "44px", "60px", "60px"])}
  }
`;

const TeaserH3 = styled.h2`
  font-size: 1.25rem;

  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    ${rValue("font-size", ["28px", "28px", "38px", "48px"])}
  }
`;

const TeaserH4 = styled.h2`
  font-size: 1.125rem;

  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    ${rValue("font-size", ["22px", "22px", "28px", "32px"])}
  }
`;

export default function LocalizedStyledText(props: IProps) {
  const { text, style, className, isTeaser } = props;

  const sharedProps = {
    className,
    style: {
      ...style,
      fontFamily: text.fontFamily,
    },
  };

  // on teaser pages (isTeaser) only h2 headers are used
  // for some reason :/
  switch (text.type) {
    case StyledTextType.Heading1:
      const H1 = isTeaser ? TeaserH1 : "h1";
      return <H1 {...sharedProps}>{serialize(text)}</H1>;
    case StyledTextType.Heading2:
      const H2 = isTeaser ? TeaserH2 : "h2";
      return <H2 {...sharedProps}>{serialize(text)}</H2>;
    case StyledTextType.Heading3:
      const H3 = isTeaser ? TeaserH3 : "h3";
      return <H3 {...sharedProps}>{serialize(text)}</H3>;
    case StyledTextType.Heading4:
      const H4 = isTeaser ? TeaserH4 : "h4";
      return <H4 {...sharedProps}>{serialize(text)}</H4>;
    case StyledTextType.Heading5:
      return <h5 {...sharedProps}>{serialize(text)}</h5>;
    case StyledTextType.Heading6:
      return <h6 {...sharedProps}>{serialize(text)}</h6>;
    case StyledTextType.Paragraph:
      return <p {...sharedProps}>{serialize(text)}</p>;
    case StyledTextType.UnorderedListItem:
    case StyledTextType.OrderedListItem:
      return <li {...sharedProps}>{serialize(text)}</li>;
    case StyledTextType.Preformatted:
      return (
        <div {...sharedProps} className="preformatted">
          {serializePreformatted(serialize(text))}
        </div>
      );
    default:
      throw new Error(`Unsupported styled text type: ${text.type}`);
  }
}

function serializePreformatted(serializedText: (string | JSX.Element)[]) {
  const [title, ...content] = (serializedText[0] as string).split("\n").filter((t) => !!t);

  return (
    <>
      <h3>{title}</h3>
      {content.map((c, i) => (
        <p key={i}>{c}</p>
      ))}
    </>
  );
}

type Range = [IStyledTextSpan["start"], IStyledTextSpan["end"], IStyledTextSpan["type"], IStyledTextSpan["data"]?];

function mapToRanges(text: string, spansInput: IStyledTextSpan[]): Range[] {
  const ranges: Range[] = [];
  const re = /\n/g;
  let match = null;

  let spans: IStyledTextSpan[] = [];
  while ((match = re.exec(text)) != null) {
    spans.push({
      start: match.index,
      end: match.index,
      type: StyledTextSpanType.Break,
    });
  }
  spans = spans.concat(spansInput);

  if (spans.length === 0) {
    return [[0, text.length - 1, StyledTextSpanType.Text]];
  }

  for (const i in spans) {
    const { start, end, type, data } = spans[i];
    const prevRangeEndIndex = ranges[ranges.length - 1] && ranges[ranges.length - 1][1];
    if (prevRangeEndIndex && prevRangeEndIndex + 1 === start) {
      ranges.push([start, end, type, data]);
    } else {
      ranges.push([prevRangeEndIndex + 1 || 0, start - 1, StyledTextSpanType.Text]);
      ranges.push([start, end, type, data]);

      const isLastSpan = parseInt(i) === spans.length - 1;
      if (isLastSpan && end < text.length) {
        ranges.push([end + 1, text.length - 1, StyledTextSpanType.Text]);
      }
    }
  }

  return ranges;
}

const Strong = styled.strong`
  hyphens: none; /* was auto */
`;

function serialize({ text, spans }: ILocalizedStyledText) {
  const ranges = mapToRanges(text, spans);
  return ranges.map((r: Range, i: number) => {
    const content = text.substring(r[0], r[1] + 1);

    if (content === "$ ") {
      return <span key={i} style={{ width: "1.5rem", height: "1rem", display: "inline-block" }} />;
    }

    if (content === "$$ ") {
      return <span key={i} style={{ width: "3rem", height: "1rem", display: "inline-block" }} />;
    }

    switch (r[2]) {
      case StyledTextSpanType.Strong:
        return <Strong key={i}>{content}</Strong>;
      case StyledTextSpanType.Em:
        return <em key={i}>{content}</em>;
      case StyledTextSpanType.Break:
        return <br key={i} />;
      case StyledTextSpanType.A:
        return content.trim() === "break" ? <br key={i} /> : <a key={i} href={r[3]?.url}>{content}</a>;
      case StyledTextSpanType.Text:
      default:
        return content;
    }
  });
}
