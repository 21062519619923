import fetch from 'isomorphic-unfetch';
import { FontFamily, IArticle } from "../domain";
import {
  IPrismicImage,
  IPrismicText,
  parseHorizontalAlignment,
  parseImage,
  parseText,
  parseVerticalAlignment,
} from "./utils";

const query = (articleId: string) => `
{
  englishArticle: article(uid: "${articleId}", lang: "en-us") {
    article_view_2
    label_color
    bold_word_color
    gradient
    font_family
    label_font_size
    label_nl
    label_en
    intro_nl
    intro_en
    header_image
    header_image_vertical_position
    header_image_horizontal_position

    ...bodyFields
  }

  dutchArticle: article(uid: "${articleId}", lang: "nl-nl") {
    ...bodyFields
  }
}

fragment bodyFields on Article {
  body {
    ... on ArticleBodyHeading {
      type
      primary {
        color
        text_align
      }
      fields {
        text_fragment
        font_size
        font_family_name
        color
        line_height
        letter_spacing
        margin_left
      }
    }
    ... on ArticleBodyQuote {
      type
      primary {
        placement
        variant
        content
        author
      }
    }
    ... on ArticleBodyText {
      type
      primary {
        text
      }
    }
    ... on ArticleBodyImage {
      type
      primary {
        variant
        image
      }
    }
    ... on ArticleBodyEmbed {
      type
      primary {
        embed
      }
    }
    ... on ArticleBodyAudio {
      type
      primary {
        audio_file {
          ... on _FileLink {
            url
          }
        }
      }
    }
    ... on ArticleBodySpacer {
      type
      primary {
        height
      }
    }
  }
}
`;

export function getArticle(props: { articleId: string }) {
  return process.env.NODE_ENV === 'development' || document.cookie.includes('io.prismic.preview')
    ? fromPrismic(props)
    : fromLocalFile(props);
}

export async function fromPrismic({ articleId }: { articleId: string }): Promise<IArticle> {
  const data: {
    englishArticle: IPrismicArticle,
    dutchArticle: IPrismicArticle,
  } = await fetch('https://dpg-jaarverslag-2020.prismic.io/api/v2')
    .then((response) => response.json())
    .then(({ refs }) => refs.find((ref: any) => ref.id === 'master').ref)
    .then((prismicRef) => {
      return fetch(`https://dpg-jaarverslag-2020.prismic.io/graphql?query=${query(articleId)}`, {
        headers: {
          'Content-Type': 'application/json',
          'Prismic-Ref': prismicRef,
        },
      })
      .then((response) => response.json())
      .then(({ data }) => data);
    });

  return {
    header: {
      isArticleView2: data.englishArticle.article_view_2,
      labelColor: data.englishArticle.label_color,
      titleColor: data.englishArticle.title_color,
      boldWordColor: data.englishArticle.bold_word_color,
      gradient: data.englishArticle.gradient,
      labelFont: data.englishArticle.font_family,
      labelSize: data.englishArticle.label_font_size,
      label: parseText(data.englishArticle.label_nl, data.englishArticle.label_en, FontFamily.Serif),
      title: parseText(data.englishArticle.title_nl, data.englishArticle.title_en, FontFamily.SansSerif),
      intro: parseText(data.englishArticle.intro_nl, data.englishArticle.intro_en, FontFamily.Serif),
      image: parseImage(data.englishArticle.header_image),
      horizontalImagePosition: parseHorizontalAlignment(data.englishArticle.header_image_horizontal_position),
      verticalImagePosition: parseVerticalAlignment(data.englishArticle.header_image_vertical_position),
    },
    content: {
      englishArticle: data.englishArticle,
      dutchArticle: data.dutchArticle,
    },
  };
}

export async function fromLocalFile({ articleId }: { articleId: string }): Promise<IArticle> {
  const response = await fetch(`/data/article-${articleId}.json`);
  return (await response.json()) as IArticle;
}

interface IPrismicArticle {
  body: any;

  article_view_2: boolean;
  font_family: FontFamily;
  label_font_size: "small" | "large";
  label_color: string;
  label_nl: IPrismicText;
  label_en: IPrismicText;
  gradient: boolean;
  title_color: string;
  bold_word_color: string;
  title_nl: IPrismicText;
  title_en: IPrismicText;
  intro_nl: IPrismicText;
  intro_en: IPrismicText;
  header_image: IPrismicImage;
  header_image_horizontal_position: string;
  header_image_vertical_position: string;
}
