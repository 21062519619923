import React, { useContext } from "react";
import styled from "styled-components";
import { FontFamily, Language } from "../domain";
import LanguageContext from "../context/LanguageContext";
import LanguageSwitch from "./LanguageSwitch";
import Color from "../colors";

const ContainerDiv = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const BlockDiv = styled.div`
  width: 100%;
  height: ${(p: { height: string }) => p.height};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)),
    url(${(p: { backgroundImageSrc: string }) => p.backgroundImageSrc});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 20% 70%;
  padding-right: 20px;
  padding-bottom: 36px;
`;

const TitleH1 = styled.h1`
  text-align: right;
  font-family: ${FontFamily.SansSerif};
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
`;

const Msg = styled.p`
  margin-top:20px;
  font-size: 80%;
`;

const i18n = {
  [Language.NL]: {
    title: "DPG Media",
    msg: "Internet Explorer wordt niet ondersteund. Gebruik Chrome, Edge of Firefox.",
  },
  [Language.EN]: {
    title: "DPG Media",
    msg: "Internet Explorer is not supported. Please use Chrome, Edge or Firefox.",
  },
};

const MobileView = () => {
  const { language } = useContext(LanguageContext);
  const t = i18n[language];

  return (
    <ContainerDiv>
      <BlockDiv height="100px">
        <Header>
          <LanguageSwitch textColor={Color.Black} />
        </Header>
      </BlockDiv>
      <BlockDiv height="100%">
        <ContentContainer backgroundImageSrc="/static_assets/cover-image_mobile.jpg">
          <TitleH1>
            {t.title}
            <Msg>{t.msg}</Msg>
          </TitleH1>
        </ContentContainer>
      </BlockDiv>
    </ContainerDiv>
  );
};

export default MobileView;
