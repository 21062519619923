import styled, { css } from 'styled-components';
import { srValue } from '../../../layout';
import { twoColumnsWidths } from '../utils';
import SidebarContent from './SidebarContent';

interface IProps {
  primary: {
    variant: Variant,
    image: {
      alt: string,
      url: string,
      dimensions: { width: number, height: number },
      small: {
        url: string,
        dimensions: { width: number, height: number },
      },
      medium: {
        url: string,
        dimensions: { width: number, height: number },
      },
      large: {
        url: string,
        dimensions: { width: number, height: number },
      },
      huge: {
        url: string,
        dimensions: { width: number, height: number },
      },
    },
  }
}

enum Variant {
  SINGLE_COLUMN = 'single-column',
  DOUBLE_COLUMN = 'double-column',
  SIDEBAR = 'sidebar',
}

const variantMapping = {
  [Variant.SINGLE_COLUMN]: css`
    width: 100%;
  `,
  [Variant.DOUBLE_COLUMN]: css`
    ${srValue("width", twoColumnsWidths)}
    ${srValue("max-width", twoColumnsWidths)}
  `,
}

const Image = styled.img<{ variant: Variant }>`
  ${(props) => variantMapping[props.variant as keyof typeof variantMapping]};
`;

export default function ImageSlice({ primary: { image, variant } }: IProps) {
  return (
    <>
      {variant === 'sidebar' ? (
        <SidebarContent>
          <Image
            variant={variant}
            src={image.url}
            alt={image.alt}
            width={image.dimensions.width}
            height={image.dimensions.height}
            srcSet={`${image.small.url} ${image.small.dimensions.width}w, ${image.medium.url} ${image.medium.dimensions.width}w, ${image.large.url} ${image.large.dimensions.width}w, ${image.huge.url} ${image.huge.dimensions.width}w`}
            sizes="300px"
          />
        </SidebarContent>
      ) : (
        <Image
          variant={variant}
          src={image.url}
          alt={image.alt}
          width={image.dimensions.width}
          height={image.dimensions.height}
          srcSet={`${image.small.url} ${image.small.dimensions.width}w, ${image.medium.url} ${image.medium.dimensions.width}w, ${image.large.url} ${image.large.dimensions.width}w, ${image.huge.url} ${image.huge.dimensions.width}w`}
          {...(variant === Variant.SINGLE_COLUMN && { sizes: '(min-width: 900px) 900px, 400px' })}
        />
      )
      }
    </>
  )
}
