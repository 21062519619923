import { createContext } from "react";
import { Language, DefaultLanguage } from "../domain";

interface IContext {
  language: Language;
  setLanguage: (language: Language) => void;
}

export default createContext<IContext>({
  language: DefaultLanguage,
  setLanguage: () => {},
});
