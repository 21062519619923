import styled from "styled-components";
import { responsiveValue, zeroResponsiveValue, breakPoints, BreakPoint } from "../../../layout";
import { headerHeight } from "../layout";

export const ContainerDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 30vh;
  overflow-y: auto;
  ${(p: { underneathHeader: boolean }) => {
    return responsiveValue("padding-top", p.underneathHeader ? zeroResponsiveValue : headerHeight);
  }}

  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    flex-direction: row;
  }
`;
