import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router";
import ErrorScreen from "../shared/ErrorScreen";
import ArrowNavigation from "./ArrowNavigation";
import Navigation from "./Navigation";
import Sections from "./Sections";
import { NavigationReason, SetPage } from "./types";
import { IRouteParams, IOverviewSection } from "../../domain";

export default function OverviewPage({ sections }: { sections: IOverviewSection[] }) {
  const params = useParams<IRouteParams>();
  const history = useHistory();
  const [navigationReason, setNavigationReason] = useState<NavigationReason | undefined>();

  useEffect(() => {
    document.body.id = "overview-page";
  }, []);

  const pages = sections.flatMap(s => s.pages);
  const currentPageId = params.pageId;
  const currentPage = pages.find(page => page._id === currentPageId);
  const currentPageIndex = pages.findIndex(page => page._id === currentPageId);

  if (!currentPageId) {
    return <Redirect to={`/${pages[0]._id}`} />;
  }

  if (!currentPage || currentPageIndex < 0) {
    return <ErrorScreen error={new Error("Page not found")} />;
  }

  const setPage: SetPage = (pageId, newNavigationReason) => {
    if (navigationReason) {
      // Don't do anything while we're still navigating
      return;
    }

    // Store the reason to navigate. This way scroll-events (with new setPage calls) will be
    // ignored when navigating with buttons (which  causes the scroll to change - animated):
    setNavigationReason(newNavigationReason);

    // Update url
    const { articleId } = params as any;
    if (!articleId) {
      history.replace(`/${pageId}`);
    }
  };

  return (
    <div>
      <Sections
        sections={sections}
        pages={pages}
        currentPage={currentPage}
        setPage={setPage}
        navigationReason={navigationReason}
        onChangePage={() => setNavigationReason(undefined)}
      />
      <Navigation sections={sections} currentPageId={currentPageId} setPage={setPage} />
      <ArrowNavigation pages={pages} currentPageIndex={currentPageIndex} setPage={setPage} />
    </div>
  );
}
