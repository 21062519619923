import styled from "styled-components";
import { FontFamily } from "../../domain";
import Color from "../../colors";

export const ContainerDiv = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const TitleH1 = styled.h1`
  font-family: ${FontFamily.SansSerif};
  font-size: 2rem;
  color: black;
  text-align: center;
`;

export const DescriptionP = styled.p`
  font-family: ${FontFamily.SansSerif};
  font-size: 1.5rem;
  color: ${Color.Gray_200};
  text-align: center;
`;
