import styled from 'styled-components';

interface IProps {
  primary: {
    height: number,
  },
}

const Div = styled.div<{ height: number }>`
  height: ${props => props.height}rem;
`;

export default function SpacerSlice({ primary }: IProps) {
  return <Div height={primary.height} />;
}
