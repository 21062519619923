import styled, { css } from 'styled-components';
import { twoColumnsWidths } from '../utils';
import { srValue, breakPoints, BreakPoint } from '../../../layout';

const ContainerDiv = styled.div<{ aspectRatio: number }>`
  position: relative;

  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    ${srValue("width", twoColumnsWidths)}
    ${srValue("max-width", twoColumnsWidths)}
  }

  ${(props) => css`${
    srValue("padding-top", twoColumnsWidths.map(w => w * props.aspectRatio))
  }`};

  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

interface IProps {
  primary: {
    embed: {
      html: string,
      width: number,
      height: number,
    },
  },
}

export default function EmbedSlice({ primary: { embed } }: IProps) {
  return (
    <ContainerDiv
      aspectRatio={ embed.height / embed.width }
      dangerouslySetInnerHTML={{ __html: embed.html }}
    />
  );
}
