import React from "react";
import { useParams } from "react-router";
import Panel from "./Panel";
import { IRouteParams, IOverviewSection } from "../../domain";

export default function ArticlePage({ sections }: { sections: IOverviewSection[] }) {
  const { pageId, articleId } = useParams<IRouteParams>();
  if (pageId && articleId) {
    return <Panel pageId={pageId} articleId={articleId} sections={sections} />;
  } else {
    return null;
  }
}
