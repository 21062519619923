import styled from 'styled-components';

const TextFragmentInline = styled.span<{ type: string }>`
  font-weight: ${props => props.type === 'strong' ? 'bold': 'normal'};
  font-style: ${props => props.type === 'em' ? 'italic': 'normal'};
`;

// @ts-ignore
export default function RichText({ text, spans }, index) {
  if (spans.length === 0) return text;

  const begin = text.slice(0, spans[0].start);

  return [
    begin ? <span key={`start-${index}`}>{begin}</span> : null,
    // @ts-ignore
    ...spans.flatMap((span, index) => {
      if (spans[index - 1]) {
        return [
          text.slice(spans[index - 1].end, span.start),
          <TextFragmentInline type={span.type} key={`TextFragmentInline-${index}`}>
            {text.slice(span.start, span.end)}
          </TextFragmentInline>,
        ];
      }

      return [
        <TextFragmentInline type={span.type} key={`TextFragmentInline-${index}`}>
          {text.slice(span.start, span.end)}
        </TextFragmentInline>,
      ];
    }),
    text.slice(spans[spans.length - 1].end),
  ].filter(Boolean);
};
