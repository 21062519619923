import { HorizontalAlignment, VerticalAlignment } from "./domain";

export enum TopInset {
  Default = "default",
  Header = "header",
}

export enum LeftInset {
  Default = "default",
  Page = "page",
}

export enum RightInset {
  Default = "default",
  Page = "page",
}

export interface IContentInset {
  top: TopInset;
  right: RightInset;
  left: LeftInset;
}

export enum BreakPoint {
  S = "s", // Small tablets
  M = "m", // Tablets
  L = "l", // Desktop
  XL = "xl", // Huge monitors
}

export const rawBreakPoints = {
  [BreakPoint.S]: 768,
  [BreakPoint.M]: 1024,
  [BreakPoint.L]: 1440,
  [BreakPoint.XL]: 1920,
};

export const breakPoints: IResponsiveValue = {
  [BreakPoint.S]: `${rawBreakPoints.s}px`,
  [BreakPoint.M]: `${rawBreakPoints.m}px`,
  [BreakPoint.L]: `${rawBreakPoints.l}px`,
  [BreakPoint.XL]: `${rawBreakPoints.xl}px`,
};

export interface IResponsiveValue {
  [BreakPoint.S]: string;
  [BreakPoint.M]: string;
  [BreakPoint.L]: string;
  [BreakPoint.XL]: string;
}

export const zeroResponsiveValue: IResponsiveValue = {
  [BreakPoint.S]: "0px",
  [BreakPoint.M]: "0px",
  [BreakPoint.L]: "0px",
  [BreakPoint.XL]: "0px",
};

export const media = (breakPoint: BreakPoint) => `@media (min-width: ${breakPoints[breakPoint]})`;

export const responsiveValue = (property: string, value: IResponsiveValue) => {
  return `
    ${property}: ${value[BreakPoint.S]};

    ${media(BreakPoint.M)} {
      ${property}: ${value[BreakPoint.M]};
    }

    ${media(BreakPoint.L)} {
      ${property}: ${value[BreakPoint.L]};
    }

    ${media(BreakPoint.XL)} {
      ${property}: ${value[BreakPoint.XL]};
    }
  `;
};

// Shorthand version of responsiveValue()

export const rValue = (property: string, values: [string, string, string, string]) => {
  return responsiveValue(property, {
    [BreakPoint.S]: values[0],
    [BreakPoint.M]: values[1],
    [BreakPoint.L]: values[2],
    [BreakPoint.XL]: values[3],
  });
};

// Converts points, designed for a specific breakpoint, into scaling values (vw's)

export const scaledValue = (value: number, breakPoint: BreakPoint): string => {
  const base = rawBreakPoints[breakPoint];
  return `${(value / base) * 100}vw`;
};

// Combines `rValue()` with `scaledValue()`'s

export const srValue = (property: string, values: number[]): string => {
  return rValue(property, [
    scaledValue(values[0], BreakPoint.S),
    scaledValue(values[1], BreakPoint.M),
    scaledValue(values[2], BreakPoint.L),
    scaledValue(values[3], BreakPoint.XL),
  ]);
};

// Converts HorizontalAlignment to flexbox

export const horizontalAlignmentToFlexbox = (position: HorizontalAlignment): string =>
  ({
    [HorizontalAlignment.Left]: "flex-start",
    [HorizontalAlignment.Center]: "center",
    [HorizontalAlignment.Right]: "flex-end",
  }[position]);

// Converts VerticalAlignment to flexbox

export const verticalAlignmentToFlexbox = (position: VerticalAlignment): string =>
  ({
    [VerticalAlignment.Top]: "flex-start",
    [VerticalAlignment.Center]: "center",
    [VerticalAlignment.Bottom]: "flex-end",
  }[position]);
