// General

import { IPrismicStyledTextData } from "./data/utils";

export enum Language {
  NL = "nl",
  EN = "en",
}

export const DefaultLanguage: Language = Language.NL;

export enum FontFamily {
  SansSerif = "sans-serif",
  Serif = "Charter, 'Bitstream Charter', serif",
  BentonModernOne = "'Benton Modern One', sans-serif",
  BentonSans = "'Benton Sans', sans-serif",
  GlyphaLtStd = "'Glypha LT Std', serif",
  SharpSansDisplay = "'Sharp Sans Display No2', sans-serif"
}

export type IColor = string;

export interface IPlainText {
  nl: string;
  en: string;
}

export interface IStyledText {
  nl: ILocalizedStyledText;
  en: ILocalizedStyledText;
}

export enum StyledTextType {
  Heading1 = "heading1",
  Heading2 = "heading2",
  Heading3 = "heading3",
  Heading4 = "heading4",
  Heading5 = "heading5",
  Heading6 = "heading6",
  Paragraph = "paragraph",
  Preformatted = "preformatted",
  UnorderedListItem = "list-item",
  OrderedListItem = "o-list-item",
}

export interface ILocalizedStyledText {
  type: StyledTextType;
  text: string;
  fontFamily: FontFamily;
  spans: IStyledTextSpan[];
}

export enum StyledTextSpanType {
  Text,
  Strong,
  Em,
  Break,
  A,
}

export interface IStyledTextSpan {
  start: number;
  end: number;
  type: StyledTextSpanType;
  data?: IPrismicStyledTextData;
}

export interface IImage {
  src: string;
  alt: string;
}

export enum VerticalAlignment {
  Top = "top",
  Center = "center",
  Bottom = "bottom",
}

export enum HorizontalAlignment {
  Left = "left",
  Center = "center",
  Right = "right",
}

// Routing

export interface IRouteParams {
  pageId?: string;
  articleId?: string;
}

// Overview

export interface IOverview {
  sections: IOverviewSection[];
}

export interface IOverviewSection {
  _id: string;
  title: IPlainText;
  navigation: IPlainText;
  color: IColor;
  pages: IOverviewPage[];
}

export interface IOverviewPage {
  _id: string;
  languageSwitchTextColor: string;
  grid: IGrid;
}

export enum GridLayout {
  A = "A", // 100%
  B = "B", // 25 % / 50% / 25%
  C = "C", // 75% / 25%
  D = "D", // 25% / 75%
  Cover = "Cover",
}

export interface IGrid {
  layout: GridLayout;
  teasers: {
    [Language.NL]: ITeaser[][],
    [Language.EN]: ITeaser[][],
  };
}

export type ITeaser = IBasicTeaser | IImageTeaser | ITitleTeaser;

interface IBaseTeaser {
  articleId?: string;
  slices?: [ISliceHeading?]
}

export interface IBasicTeaser extends IBaseTeaser {
  _type: "basic";
  title?: IStyledText;
  body?: IStyledText;
  image?: IImage;
  image_en?: IImage;
  imagePosition: VerticalAlignment;
  fontFamily?: FontFamily;
}

export interface IImageTeaser extends IBaseTeaser {
  _type: "image";
  labelFont?: FontFamily;
  labelSize?: "small" | "large";
  label?: IStyledText;
  title?: IStyledText;
  body?: IStyledText;
  image?: IImage;
  image_en?: IImage;
  horizontalImagePosition: HorizontalAlignment;
  verticalImagePosition: VerticalAlignment;
  textColor: IColor;
  boldWordColor?: string;
  textWidth: number;
  textAlignment: HorizontalAlignment;
  verticalTextPosition: VerticalAlignment;
  horizontalTextPosition: HorizontalAlignment;
  gradientOpacity: number;
  gradientColor: IColor;
  animation: boolean;
}

export interface ITitleTeaser extends IBaseTeaser {
  _type: "title";
  title?: IStyledText;
}

// Article

export interface IArticle {
  header: IArticleHeader;
  content: {
    englishArticle: any;
    dutchArticle: any;
  };
}

export interface IArticleHeader {
  isArticleView2?: boolean;
  labelFont?: FontFamily;
  labelSize?: "small" | "large";
  labelColor?: string;
  label?: IStyledText;
  titleColor?: string;
  boldWordColor?: string;
  gradient?: boolean;
  title?: IStyledText;
  intro?: IStyledText;
  image?: IImage;
  horizontalImagePosition: HorizontalAlignment;
  verticalImagePosition: VerticalAlignment;
}

export type IArticleContent =
  | IArticleTextContent
  | IArticleImageContent
  | IArticleTableContent
  | IArticleEmbedContent
  | IArticleSidebarStatisticContent
  | IArticleSidebarImageContent
  | IArticleHR
  | IArticleOL
  | IArticleUL
  | IArticleContentHeader;

export interface IArticleContentHeader {
  _type: "intro";
  text: ILocalizedStyledText;
}

export interface IArticleHR {
  _type: "hr";
  width: "single" | "double";
}

export interface IArticleTextContent {
  _type: "text";
  text: ILocalizedStyledText;
}

export interface IArticleUL {
  _type: "ul";
  text: IArticleTextContent[];
}
export interface IArticleOL {
  _type: "ol";
  text: IArticleTextContent[];
}

export interface IArticleImageContent {
  _type: "image";
  image: IImage;
  delighter?: any;
}

export interface IArticleTableContent {
  _type: "table";
  table: string[][];
}

export interface IArticleEmbedContent {
  _type: "embed";
  url?: string;
  embed_url?: string;
  width?: number;
  height?: number;
}

export interface IArticleSidebarStatisticContent {
  _type: "sidebar-statistic";
  number: ILocalizedStyledText; // Large text, usually a percentage
  text: ILocalizedStyledText; // Smaller text, describing the number
}

export interface IArticleSidebarImageContent {
  _type: "sidebar-image";
  image: IImage;
  delighter?: any;
}

// Slices

export interface ISliceHeading {
  type: string,
  primary: { text_align: string },
  fields: [{
    text_fragment: [ILocalizedStyledText],
    font_size?: number,
    font_family_name: string,
    line_height?: number,
    letter_spacing?: number,
    margin_left?: number,
  }],
}
