import {
  FontFamily,
  GridLayout,
  HorizontalAlignment,
  IColor,
  IImage,
  IStyledText,
  IStyledTextSpan,
  StyledTextType,
  VerticalAlignment,
  StyledTextSpanType,
} from "../domain";

export type IPrismicText = [
  {
    type: StyledTextType;
    text: string;
    spans: IPrismicStyledTextSpan[];
  },
];

export interface IPrismicStyledTextSpan {
  start: number;
  end: number;
  type: string;
  data?: IPrismicStyledTextData;
}

export interface IPrismicStyledTextData {
  link_type: "Web";
  url: string;
}

export interface IPrismicImage {
  url: string;
  alt: string;
}

export function parseImage(prismicValue?: IPrismicImage): IImage | undefined {
  return prismicValue && { src: prismicValue.url, alt: prismicValue.alt };
}

export function parseText(
  nl: IPrismicText | undefined,
  en: IPrismicText | undefined,
  fontFamily: FontFamily,
): IStyledText | undefined {
  const nlText = nl?.[0]?.text;
  const enText = en && en[0] && en[0].text;

  if (nlText) {
    const nlResult = {
      type: parseStyledTextType(nl?.[0]?.type),
      text: nlText,
      spans: parseStyledTextSpans(nl && nl[0] && nl[0].spans),
      fontFamily,
    };

    return {
      nl: nlResult,
      en: !enText ? nlResult : {
        type: parseStyledTextType(en?.[0]?.type || "heading2"),
        text: enText,
        spans: parseStyledTextSpans(en && en[0] && en[0].spans),
        fontFamily,
      },
    };
  }
}

export function parseStyledTextType(prismicValue?: string): StyledTextType {
  switch (prismicValue) {
    case "heading1":
      return StyledTextType.Heading1;
    case "heading2":
      return StyledTextType.Heading2;
    case "heading3":
      return StyledTextType.Heading3;
    case "heading4":
      return StyledTextType.Heading4;
    case "heading5":
      return StyledTextType.Heading5;
    case "heading6":
      return StyledTextType.Heading6;
    case "paragraph":
      return StyledTextType.Paragraph;
    case "preformatted":
      return StyledTextType.Preformatted;
    case "list-item":
      return StyledTextType.UnorderedListItem;
    case "o-list-item":
      return StyledTextType.OrderedListItem;
    default:
      throw new Error(`Unsupported styled text type: ${prismicValue}`);
  }
}

export function parseStyledTextSpans(prismicValue?: (IPrismicStyledTextSpan | undefined)[]): IStyledTextSpan[] {
  //@ts-ignore
  return (prismicValue || []).map(parseStyledTextSpan).filter((t) => !!t);
}

export function parseStyledTextSpan(prismicValue?: IPrismicStyledTextSpan): IStyledTextSpan | undefined {
  if (prismicValue) {
    return {
      start: prismicValue.start,
      end: prismicValue.end,
      type: parseStyledTextSpanType(prismicValue.type),
      data: prismicValue.data,
    };
  }
}

export function parseStyledTextSpanType(prismicValue?: string): StyledTextSpanType {
  switch (prismicValue) {
    case "text":
      return StyledTextSpanType.Text;
    case "strong":
      return StyledTextSpanType.Strong;
    case "em":
      return StyledTextSpanType.Em;
    case "hyperlink":
      return StyledTextSpanType.A;
    default:
      throw new Error(`Unsupported styled text span type: ${prismicValue}`);
  }
}

export function parseGridLayout(prismicValue?: string): GridLayout {
  switch (prismicValue) {
    case "A":
      return GridLayout.A;
    case "B":
      return GridLayout.B;
    case "C":
      return GridLayout.C;
    case "D":
      return GridLayout.D;
    case "Cover":
      return GridLayout.Cover;
    default:
      throw new Error(`Unsupported grid: ${prismicValue}`);
  }
}

export function parseHorizontalAlignment(prismicValue?: string): HorizontalAlignment {
  switch (prismicValue) {
    case undefined:
    case null:
      return HorizontalAlignment.Center;
    case "left":
      return HorizontalAlignment.Left;
    case "center":
      return HorizontalAlignment.Center;
    case "right":
      return HorizontalAlignment.Right;
    default:
      throw new Error(`Unsupported horizontal alignment: ${prismicValue}`);
  }
}

export function parseVerticalAlignment(prismicValue?: string): VerticalAlignment {
  switch (prismicValue) {
    case undefined:
    case null:
      return VerticalAlignment.Center;
    case "top":
      return VerticalAlignment.Top;
    case "center":
      return VerticalAlignment.Center;
    case "bottom":
      return VerticalAlignment.Bottom;
    default:
      throw new Error(`Unsupported vertical alignment: ${prismicValue}`);
  }
}

export function parseColor(prismicValue?: string): IColor {
  return prismicValue || "black";
}

export function parsePercentage(prismicValue: number | undefined, fallbackValue: number): number {
  return typeof prismicValue == "undefined" ? fallbackValue : prismicValue / 100;
}
