import { useContext } from "react";
import LanguageContext from "../context/LanguageContext";
import { Language } from "../domain";
import { ContainerDiv, LanguageButton, SeparatorDiv } from "./LanguageSwitch.styled";

interface IProps {
  textColor: string;
}

export default function LanguageSwitch(props: IProps) {
  const { language, setLanguage } = useContext(LanguageContext);
  const changeLanguage = (desiredLang: Language) => {
    setLanguage(desiredLang);
  };

  const textColor = props.textColor || "black";

  return (
    <ContainerDiv>
      <LanguageButton isActive={language === Language.NL} onClick={() => changeLanguage(Language.NL)} textColor={textColor}>
        NL
      </LanguageButton>
      <SeparatorDiv textColor={textColor} />
      <LanguageButton isActive={language === Language.EN} onClick={() => changeLanguage(Language.EN)} textColor={textColor}>
        EN
      </LanguageButton>
    </ContainerDiv>
  );
}
