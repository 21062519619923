import fetch from 'isomorphic-unfetch';
import { FontFamily, IOverview, ITeaser, ISliceHeading, VerticalAlignment } from '../domain';
import {
  IPrismicImage,
  IPrismicText,
  parseColor,
  parseGridLayout,
  parseHorizontalAlignment,
  parseImage,
  parsePercentage,
  parseText,
  parseVerticalAlignment,
} from './utils';

const query = `
{
  englishPages: allPages(first: 100, sortBy: title_ASC, lang: "en-us") {
    edges {
      node {
        _meta {
          uid
          alternateLanguages {
            id
            lang
          }
        }
        title
        grid
        language_switch_color
        section {
          ... on Section {
            _meta {
              uid
            }
            color
            title_nl
            title_en
            navigation_title_nl
            navigation_title_en
          }
        }
        teasers_left {
          teaser {
            ... on Teaser {
              ...basicFields
            }
          }
        }
        teasers_center {
          teaser {
            ... on Teaser {
              ...basicFields
            }
          }
        }
        teasers_right {
          teaser {
            ... on Teaser {
              ...basicFields
            }
          }
        }
      }
    }
  }
  dutchPages: allPages(first: 100, lang: "nl-nl") {
    edges {
      node {
        _meta {
          id
        }
        teasers_left {
          teaser {
            ... on Teaser {
              ...basicFields
            }
          }
        }
        teasers_center {
          teaser {
            ... on Teaser {
              ...basicFields
            }
          }
        }
        teasers_right {
          teaser {
            ... on Teaser {
              ...basicFields
            }
          }
        }
      }
    }
  }
}

fragment basicFields on Teaser {
  article {
    ... on Article {
      _meta {
        uid
      }
    }
  }
  body {
    ... on TeaserBodyHeading {
      type
      primary {
        text_align
      }
      fields {
        text_fragment
        font_size
        font_family_name
        color
        line_height
        letter_spacing
        margin_left
      }
    }
  }
  type
  label_font
  label_size
  label_nl
  label_en
  title_nl
  title_en
  body_nl
  body_en
  image
  image_en
  basic_teaser_image_position
  basic_teaser_font
  image_teaser_horizontal_text_position
  image_teaser_vertical_text_position
  image_teaser_text_color
  image_teaser_bold_word_color
  image_teaser_text_alignment
  image_teaser_text_width
  image_teaser_horizontal_image_position
  image_teaser_vertical_text_position
  image_teaser_gradient_opacity
  image_teaser_gradient_color
  image_animation
}
`;

export function getOverview() {
  return process.env.NODE_ENV === 'development' || document.cookie.includes('io.prismic.preview')
    ? fromPrismic()
    : fromLocalFile();
}

export async function fromPrismic(): Promise<IOverview> {
  const data: {
    englishPages: { edges: [ IPrismicPage ] },
    dutchPages: { edges: [ IPrismicPage ] }
  } =
    await fetch('https://dpg-jaarverslag-2020.prismic.io/api/v2')
      .then((response) => response.json())
      .then(({ refs }) => refs.find((ref: any) => ref.id === 'master').ref)
      .then((prismicRef) => {
        return fetch(`https://dpg-jaarverslag-2020.prismic.io/graphql?query=${query}`, {
          headers: {
            'Content-Type': 'application/json',
            'Prismic-Ref': prismicRef,
          },
        })
        .then((response) => response.json())
        .then(({ data }) => data)
      });

  return {
    // @ts-ignore
    sections: Object.values(
      data.englishPages.edges
        .map((page: any) => {
          const alternateDutchPageId = page.node._meta.alternateLanguages
            ?.find(({ lang }: { lang: string }) => lang === 'nl-nl')
            ?.id;

          const alternateDutchPage = data.dutchPages.edges
            .find((edge: any) => edge.node._meta.id === alternateDutchPageId)

          return {
            '_id': page.node.section._meta.uid,
            'title': {
              'nl': page.node.section.title_nl[0].text,
              'en': page.node.section.title_en[0].text,
            },
            'navigation': {
              'nl': page.node.section.navigation_title_nl[0].text,
              'en': page.node.section.navigation_title_en[0].text,
            },
            'color': page.node.section.color,
            'pages': [
              {
                '_id': page.node._meta.uid,
                'languageSwitchTextColor': page.node.language_switch_color,
                'grid': {
                  'layout': parseGridLayout(page.node.grid),
                  'teasers': {
                    'nl': [
                      // @ts-ignore
                      alternateDutchPage?.node.teasers_left.map(toTeaser).filter(Boolean),
                      // @ts-ignore
                      alternateDutchPage?.node.teasers_center.map(toTeaser).filter(Boolean),
                      // @ts-ignore
                      alternateDutchPage?.node.teasers_right.map(toTeaser).filter(Boolean),
                    ],
                    'en': [
                      page.node.teasers_left.map(toTeaser).filter(Boolean),
                      page.node.teasers_center.map(toTeaser).filter(Boolean),
                      page.node.teasers_right.map(toTeaser).filter(Boolean),
                    ],
                  },
                },
              },
            ],
          }
        })
        .reduce((sections, page) => {
          // @ts-ignore
          if (sections[page._id])
          // @ts-ignore
            sections[page._id].pages.push(page.pages[0])
          else
          // @ts-ignore
            sections[page._id] = page;

          // @ts-ignore
          sections[page._id].pages.sort((pageA, pageB) =>
            pageA._id.localeCompare(pageB._id)
          )
          return sections;
        }, {})
      )
      .sort((sectionA: any, sectionB: any) =>
        sectionA._id.localeCompare(sectionB._id)
      )
  }
}

export async function fromLocalFile(): Promise<IOverview> {
  const response = await fetch('/data/overview.json');
  return (await response.json());
}

interface IPrismicPage {
  uid?: string;
  data: {
    section: { data: {
      uid: string,
      color: string,
      title_nl: IPrismicText,
      title_en: IPrismicText,
      navigation_title_nl: IPrismicText,
      navigation_title_en: IPrismicText,
    }};
    title: IPrismicText;
    grid: string;
    teasers_left: { teaser: IPrismicTeaser }[];
    teasers_center: { teaser: IPrismicTeaser }[];
    teasers_right: { teaser: IPrismicTeaser }[];
    language_switch_color: string;
  };
}

interface IPrismicTeaser {
  id: string;
  article?: { _meta: { uid?: string } };
  type?: ITeaser['_type'];
  label_font?: FontFamily;
  label_size?: 'small' | 'large';
  label_nl?: IPrismicText;
  label_en?: IPrismicText;
  title_nl?: IPrismicText;
  title_en?: IPrismicText;
  body_nl?: IPrismicText;
  body_en?: IPrismicText;
  image?: IPrismicImage;
  image_en?: IPrismicImage;
  basic_teaser_image_position?: string;
  basic_teaser_font?: FontFamily;
  image_teaser_horizontal_text_position?: string;
  image_teaser_vertical_text_position?: string;
  image_teaser_text_color?: string;
  image_teaser_bold_word_color?: string;
  image_teaser_text_alignment?: string;
  image_teaser_text_width?: number;
  image_teaser_horizontal_image_position?: string;
  image_teaser_vertical_image_position?: string;
  image_teaser_gradient_opacity?: number;
  image_teaser_gradient_color?: string;
  image_animation: boolean;

  body?: [ISliceHeading?]
}

function toTeaser(slot: { teaser: IPrismicTeaser }): ITeaser | undefined {
  if (!slot.teaser)
    return;
  const data = slot.teaser;

  switch (data.type) {
    case 'basic':
      return {
        _type: 'basic',
        articleId: data.article?._meta.uid,
        title: parseText(data.title_nl, data.title_en, FontFamily.SansSerif),
        body: parseText(data.body_nl, data.body_en, FontFamily.Serif),
        image: parseImage(data.image),
        image_en: parseImage(data.image_en),
        imagePosition: parseVerticalAlignment(data.basic_teaser_image_position) || VerticalAlignment.Top,
        fontFamily: data.basic_teaser_font,
        slices: data.body,
      };

    case 'title':
      return {
        _type: 'title',
        articleId: data.article?._meta.uid,
        title: parseText(data.title_nl, data.title_en, FontFamily.Serif),
        slices: data.body,
      };

    case 'image':
      return {
        _type: data.type,
        articleId: data.article?._meta.uid,
        labelFont: data.label_font,
        labelSize: data.label_size,
        label: parseText(data.label_nl, data.label_en, FontFamily.Serif),
        title: parseText(data.title_nl, data.title_en, FontFamily.SansSerif),
        body: parseText(data.body_nl, data.body_en, FontFamily.Serif),
        image: parseImage(data.image),
        image_en: parseImage(data.image_en),
        textColor: parseColor(data.image_teaser_text_color),
        boldWordColor: data.image_teaser_bold_word_color,
        textAlignment: parseHorizontalAlignment(data.image_teaser_text_alignment),
        textWidth: parsePercentage(data.image_teaser_text_width, 1),
        horizontalTextPosition: parseHorizontalAlignment(data.image_teaser_horizontal_text_position),
        verticalTextPosition: parseVerticalAlignment(data.image_teaser_vertical_text_position),
        horizontalImagePosition: parseHorizontalAlignment(data.image_teaser_horizontal_image_position),
        verticalImagePosition: parseVerticalAlignment(data.image_teaser_vertical_image_position),
        gradientColor: parseColor(data.image_teaser_gradient_color),
        gradientOpacity: parsePercentage(data.image_teaser_gradient_opacity, 0),
        slices: data.body,
        animation: data.image_animation,
      };

    default:
      throw new Error(`Unsupported teaser: ${data.type}`);
  }
}
