import React, { useContext } from "react";
import LanguageContext from "../../context/LanguageContext";
import * as Styled from "./Content.styled";
import Header from "./Header";
import { ArticleState } from "./utils";
import {
  Language,
  IArticle,
  IArticleContent,
  IArticleContentHeader,
  IOverviewSection,
} from "../../domain";

import IntroTextContent from "./content/IntroTextContent";

import QuoteSlice from "./content/QuoteSlice";
import TextSlice from "./content/TextSlice";
import ImageSlice from "./content/ImageSlice";
import EmbedSlice from "./content/EmbedSlice";
import AudioSlice from "./content/AudioSlice";
import SpacerSlice from "./content/SpacerSlice";

interface IProps {
  onCloseArticle: () => void;
  article: IArticle;
  articleState: ArticleState;
  sectionColor: string;
  pageId: string;
  sections: IOverviewSection[];
}

const makeContentWithArticleHeader = (props: IProps, language: Language) =>
  props.article.header.isArticleView2 && props.article.header.intro
    ? [
        {
          _type: "intro",
          text: props.article.header.intro[language],
        } as IArticleContentHeader,
      ]
    : [];

const Content = React.memo((props: IProps) => {
  const languageMapping: { [key: string]: string } = {
    "nl": "dutchArticle",
    "en": "englishArticle",
  };

  const { sectionColor, pageId, onCloseArticle } = props;
  const { language } = useContext(LanguageContext);
  const articleContent = makeContentWithArticleHeader(props, language);
  // @ts-ignore
  const slicesContent = props.article.content[languageMapping[language]]?.body || [];

  const renderContent = (content: IArticleContent) => {
    switch (content._type) {
      case "intro":
        return <IntroTextContent content={content} />;
      }
  };

  const renderSlice = (slice: any) => {
    switch (slice.type) {
      case "quote":
        return <QuoteSlice {...slice} />;
      case "text":
        return <TextSlice {...slice} />;
      case "image":
        return <ImageSlice {...slice} />;
      case "embed":
        return <EmbedSlice {...slice} />;
      case "audio":
        return <AudioSlice {...slice} />;
      case "spacer":
        return <SpacerSlice {...slice} />;
    }
  }

  return (
    <Styled.ContainerDiv>
      <Header
        onCloseArticle={onCloseArticle}
        header={props.article.header}
        content={
          slicesContent.find((slice: any) => slice.type === 'heading')
        }
        pageId={pageId}
        sectionColor={sectionColor}
        sections={props.sections}
      />
      <Styled.ContentItemsDiv>
        {
          articleContent
            .map((content, index) => (
              <Styled.ContentItemDiv key={index}>
                {renderContent(content)}
              </Styled.ContentItemDiv>
            ))
        }
        {
          slicesContent.map((slice: any, index: number) => (
            <Styled.ContentItemDiv key={`${slice.type}-${index}`}>
              {renderSlice(slice)}
            </Styled.ContentItemDiv>
          ))
        }
      </Styled.ContentItemsDiv>
    </Styled.ContainerDiv>
  );
});

export default Content;
