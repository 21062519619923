enum Color {
  Purple = "#783c96",
  Red = "#e6463c",
  Pink = "#d23278",
  Yellow = "#fabb22",

  White = "#FFFFFF",
  Black = "#000000",

  Gray_200 = "#E5E7EB",
  Gray_400 = "#9CA3AF",
  Gray_600 = "#4B5563",
  Gray_800 = "#1F2937",
  Gray_900 = "#111827",
}

export default Color;
