import ReactDOM from "react-dom";
import App from "./components/App";
import "./global-styles/hacks.css";
import "./global-styles/reset.css";
import "./global-styles/delighters.css";
import "./global-styles/animations.css";
import "./global-styles/common.css";
import "./global-styles/fonts.css";

ReactDOM.render(<App />, document.getElementById("root"));
