import styled from 'styled-components';
import Color from '../../colors';
import { FontFamily } from '../../domain';
import { rValue } from '../../layout';
import RichText from '../ArticlePage/content/RichText';

interface IProps {
  content: any;
}

const Heading = styled.h1<{ textAlign: string, color: string }>`
  margin-bottom: 1rem;
  text-align: ${props => props.textAlign};
  color: ${props => Color[props.color as keyof typeof Color]};
  ${rValue('font-size', ['0.4rem', '0.6rem', '0.8rem', '1rem'])}
`;

export default function HeadingSlice({ content }: IProps) {
  if (content.fields)
    content.items = content.fields;

  return (
    <Heading
      textAlign={content.primary.text_align}
      color={content.primary.color}
    >
      {content.items.map((item: any, index: number) => (
        <span
          style={{
            fontFamily: FontFamily[item.font_family_name as keyof typeof FontFamily],
            fontSize: `${item.font_size ? item.font_size : 2}em`,
            ...(item.color && { color: item.color }),
            ...(item.letter_spacing && { letterSpacing: `${item.letter_spacing}em` }),
            ...(item.line_height && { lineHeight: `${item.line_height}%` }),
            ...(item.margin_left && { marginLeft: `${item.margin_left}em` }),
          }}
          key={`TextFragment-span-${index}`}
        >
          {
            item.text_fragment
              .map(RichText)
              .flatMap((line: any, index: number) => [<br key={`br-${index}`} />, line])
              .slice(1)
          }
        </span>
      ))}
    </Heading>
  );
}
