import { ITitleTeaser } from "../../../domain";
import { IContentInset } from "../../../layout";
import { ContainerDiv, ContainerTitle } from "./TitleTeaser.styled";
import HeadingSlice from '../../shared/HeadingSlice';

interface IProps {
  teaser: ITitleTeaser;
  contentInset: IContentInset;
  isLastInColumn: boolean;
  sectionColor: string;
}

export default function TitleTeaser({ teaser, contentInset, isLastInColumn, sectionColor }: IProps) {
  return (
    <ContainerDiv contentInset={contentInset} isLastInColumn={isLastInColumn}>
      {teaser.slices && (
        <ContainerTitle>
          <HeadingSlice content={
            teaser.slices.find((slice) => slice?.type === 'heading')
          }/>
        </ContainerTitle>
      )}
    </ContainerDiv>
  );
}
