import styled from "styled-components";
import { BreakPoint, breakPoints } from "../../layout";

export const SectionsDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
`;

export const SectionDiv = styled.div``;

export const PagesDiv = styled.div`
  display: flex;
  height: 100%;
`;

export const PageDiv = styled.div`
  scroll-snap-align: center;
  width: 100vw;
  height: 100%;
`;

export const Logo = styled.img`
  z-index: 1;
  position: absolute;
  right: 6vw;
  bottom: 8rem;
  width: 212px;

  @media (min-width: ${breakPoints[BreakPoint.L]}) {
    right: 8vw;
    bottom: 12rem;
    width: 424px;
  }
`;
