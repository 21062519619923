import { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import LanguageContext from "../context/LanguageContext";
import { Language, DefaultLanguage } from "../domain";
import IEView from "./IEView";
import OverviewData from "./OverviewData";

export default function App() {
  const [language, setLanguage] = useState<Language>(DefaultLanguage);
  var ua = window.navigator.userAgent;
  const isIE = ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0;

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {isIE && <IEView />}
      {!isIE && (
        <Router>
          <Route path="/:pageId?/:articleId?">
            <OverviewData />
          </Route>
        </Router>
      )}
    </LanguageContext.Provider>
  );
}
