import styled from "styled-components";
import Color from "../../colors";
import { FontFamily } from "../../domain";
import { responsiveValue } from "../../layout";
import { navigationHeight } from "./layout";

export const ContainerDiv = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  padding-top: 6px;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const InnerContentDiv = styled.div`
  width: 100%;
  height: 100%;
`;

export const ItemsDiv = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  background: white;
  ${responsiveValue("min-height", navigationHeight)}
`;

export const ItemButton = styled.button`
  flex-grow: 1;
  flex-shrink: 0;
  width: ${(p: { width: number }) => `${p.width}px`};
  border-top: 1px solid ${Color.Gray_200};
  border-right: 1px solid ${Color.Gray_200};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-family: ${FontFamily.SharpSansDisplay};
  padding: 0.5rem 1rem;
  cursor: pointer;
  flex-direction: column;

  text-overflow: ellipsis;
  line-height: 1.2em;

  &:last-child {
    border-right: none;
  }
`;

interface IProgressProps {
  currentSectionIndex: number;
  amountOfPages: number;
  currentPageIndex: number;
  itemWidth: number;
  currentSectionColor: any;
}

export const ProgressDiv = styled.div`
  height: 6px;
  background: ${(p: IProgressProps) => p.currentSectionColor? p.currentSectionColor : 'black' };
  width: ${(p: IProgressProps) => `${((p.currentPageIndex + 1) / p.amountOfPages) * (p.currentPageIndex === 0 ? p.itemWidth / 2 : p.itemWidth)}px`};
  transform: ${(p: IProgressProps) => `translateX(${p.currentSectionIndex === 0 ? 0 : (p.currentSectionIndex-0.5) * p.itemWidth}px)`};
  transform-origin: 0 0;
  transition: all 0.5s ease-out;
`;

export const LogoImg = styled.img`
  max-height: 65%;
`;
