import styled from 'styled-components';
import prismicDom from 'prismic-dom';
import { rValue } from '../../../layout';
import { IPrismicStyledTextSpan } from '../../../data/utils';

const Container = styled.div`
  h2 {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
  }

  p {
    line-height: 1.6em;
    ${rValue("font-size", ["18px", "18px", "20px", "22px"])}
    margin-bottom: 1.8rem;
  }

  a {
    color: inherit;
    font-style: italic;
  }
`;

interface IProps {
  primary: {
    text: [{
      type: string,
      text: string,
      spans: [IPrismicStyledTextSpan],
    }]
  }
}

export default function TextSlice({ primary: { text } }: IProps) {
  return <Container
    dangerouslySetInnerHTML={{ __html: prismicDom.RichText.asHtml(
      text.filter(({ text }) => /\S/.test(text)),
    ) }}
  />;
}
