import styled from 'styled-components';

interface IProps {
  primary: {
    audio_file: {
      url: string,
    },
  },
}

const Audio = styled.audio`
  width: 100%;
`;

export default function AudioSlice({ primary }: IProps) {
  return <Audio controls src={primary.audio_file.url} />;
}
