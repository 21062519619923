import { css } from "styled-components";
import {
  BreakPoint,
  IContentInset,
  IResponsiveValue,
  LeftInset,
  responsiveValue,
  RightInset,
  TopInset,
} from "../../layout";

export const headerHeight: IResponsiveValue = {
  [BreakPoint.S]: "60px",
  [BreakPoint.M]: "60px",
  [BreakPoint.L]: "60px",
  [BreakPoint.XL]: "80px",
};

export const navigationHeight: IResponsiveValue = {
  [BreakPoint.S]: "50px",
  [BreakPoint.M]: "50px",
  [BreakPoint.L]: "50px",
  [BreakPoint.XL]: "66px",
};

export const pageGutter: IResponsiveValue = {
  [BreakPoint.S]: "40px",
  [BreakPoint.M]: "40px",
  [BreakPoint.L]: "80px",
  [BreakPoint.XL]: "120px",
};

export const horizontalGutter: IResponsiveValue = {
  [BreakPoint.S]: "40px",
  [BreakPoint.M]: "40px",
  [BreakPoint.L]: "60px",
  [BreakPoint.XL]: "60px",
};

export const topGutter: IResponsiveValue = {
  [BreakPoint.S]: "30px",
  [BreakPoint.M]: "30px",
  [BreakPoint.L]: "40px",
  [BreakPoint.XL]: "42px",
};

export const bottomGutter: IResponsiveValue = {
  [BreakPoint.S]: "30px",
  [BreakPoint.M]: "30px",
  [BreakPoint.L]: "40px",
  [BreakPoint.XL]: "70px",
};

export const teaserInset = css`
  ${(p: { contentInset: IContentInset }) => {
    const getInset = (breakPoint: BreakPoint) =>
      ({
        [TopInset.Default]: topGutter[breakPoint],
        [TopInset.Header]: `calc(${headerHeight[breakPoint]} + ${topGutter[breakPoint]})`,
      }[p.contentInset.top]);

    return responsiveValue("padding-top", {
      [BreakPoint.S]: getInset(BreakPoint.S),
      [BreakPoint.M]: getInset(BreakPoint.M),
      [BreakPoint.L]: getInset(BreakPoint.L),
      [BreakPoint.XL]: getInset(BreakPoint.XL),
    });
  }}

  ${(p: { contentInset: IContentInset }) => {
    const getInset = (breakPoint: BreakPoint) =>
      ({
        [RightInset.Default]: horizontalGutter[breakPoint],
        [RightInset.Page]: pageGutter[breakPoint],
      }[p.contentInset.right]);

    return responsiveValue("padding-right", {
      [BreakPoint.S]: getInset(BreakPoint.S),
      [BreakPoint.M]: getInset(BreakPoint.M),
      [BreakPoint.L]: getInset(BreakPoint.L),
      [BreakPoint.XL]: getInset(BreakPoint.XL),
    });
  }}

  ${(p: { contentInset: IContentInset }) => {
    const getInset = (breakPoint: BreakPoint) =>
      ({
        [LeftInset.Default]: horizontalGutter[breakPoint],
        [LeftInset.Page]: pageGutter[breakPoint],
      }[p.contentInset.left]);

    return responsiveValue("padding-left", {
      [BreakPoint.S]: getInset(BreakPoint.S),
      [BreakPoint.M]: getInset(BreakPoint.M),
      [BreakPoint.L]: getInset(BreakPoint.L),
      [BreakPoint.XL]: getInset(BreakPoint.XL),
    });
  }}

  ${responsiveValue("padding-bottom", bottomGutter)}
`;
