import styled from "styled-components";
import { rValue, srValue, BreakPoint, breakPoints, rawBreakPoints } from "../../layout";
import { FontFamily, IArticleHeader } from "../../domain";
import { ReactComponent as ArrowLeftSvg } from "../shared/ArrowLeft.svg";
import StyledText, { IProps as IStyledTextProps } from "../shared/StyledText";
import Color from "../../colors";

export const ContainerDiv = styled.div`
  display: flex;
  margin-bottom: 48px;
  ${rValue("flex-direction", ["column", "row", "row", "row"])}

  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    ${srValue("padding-left", [40, 40, 80, 120])}
  }
`;

export const ImageColumnDiv = styled.div`
  ${rValue("margin-bottom", ["24px", "0px", "0px", "0px"])}

  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    position: relative;
    ${srValue("width", [728, 533, 686, 962])}
  }
`;

export const WideImageColumnDiv = styled.div`
  position: relative;
  width: 100%;
  ${rValue("margin-bottom", ["24px", "0px", "0px", "0px"])}
`;

export const BackToPageLink = styled.a`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 28px 32px 28px 1rem;
  font-size: 18px;
  font-family: ${FontFamily.SharpSansDisplay};
  font-weight: 500;
  color: ${Color.Black};
  text-decoration: none;
  transition: color 0.3s ease-in;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0,0,0,.3);
  z-index: 5;

  &:hover {
    color: black;
  }

  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    right: auto;
  }
`;

export const ArrowLeft = styled(ArrowLeftSvg)`
  width: 1.3rem;
  height: 1.9rem;
  margin-top: -0.5rem;
  margin-right: 1.3rem;
`;

export const ImageDiv = styled.div`
  background-image: url(${(p: { header: IArticleHeader }) => p.header.image?.src ? p.header.image?.src + "&w=1200&fit=max" : p.header.image?.src});
  background-position-x: ${(p: { header: IArticleHeader }) => p.header.horizontalImagePosition};
  background-position-y: ${(p: { header: IArticleHeader }) => p.header.verticalImagePosition};
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  ${rValue("min-height", ["500px", "940px", "940px", "1200px"])};
`;

export const ContentImageDiv = styled.div`
  background-image: ${(p: { header: IArticleHeader }) => {
    const headerImgSrc = p.header.image?.src ? p.header.image?.src + "&w=1200&fit=max": p.header.image?.src;
    return p.header.gradient
      ? `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url(${headerImgSrc})`
      : `url(${headerImgSrc})`;
  }};
  background-position-x: ${(p: { header: IArticleHeader }) => p.header.horizontalImagePosition};
  background-position-y: ${(p: { header: IArticleHeader }) => p.header.verticalImagePosition};
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${rValue("padding", ["14px 36px", "", "", ""])};
  min-height: 80vh;
`;

export const ContentColumnDiv = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${rValue("margin-left", ["1rem", "40px", "40px", "40px"])}
`;

export const ContentDiv = styled.div`
  ${srValue("width", [660, 370, 457, 703])}
`;

export const LabelStyledText = styled(StyledText)`
  font-size: ${(props: any) => (props.size === "small" ? "1.5em" : "1.85em")};
  font-family: ${(props: any) => props.font} !important;
  line-height: 1.22;
  letter-spacing: 0.7px;
  margin-bottom: 12px;
`;

export const ImageLabelStyledText = styled(StyledText)`
  font-family: ${(props: any) => props.font} !important;
  line-height: 1.22;
  letter-spacing: 0.7px;
  margin-bottom: 12px;
  ${(props: IStyledTextProps) => {
    return rValue(
      "font-size",
      props.size === "small" ? ["18px", "24px", "24px", "30px"] : ["22px", "28px", "28px", "36px"],
    );
  }};
  color: ${(props: IStyledTextProps) => {
    return props.color;
  }};
`;

export const TitleStyledText = styled(StyledText)`
  line-height: 1.25;
  font-size: 58px;
  font-weight: 300;
  letter-spacing: 1.8px;
  margin-top: 0;
  margin-bottom: 28px;
  ${rValue("font-size", ["40px", "46px", "52px", "88px"])}

  @media (min-width: ${breakPoints[BreakPoint.S]}) and (max-width: ${rawBreakPoints[BreakPoint.M] - 1}px) {
    max-width: 60%;
  }

  strong {
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: 1.8px;
    line-height: 0.9;
    color: ${(props: IStyledTextProps) => props.boldWordColor};
  }
`;

export const ImageTitleStyledText = styled(StyledText)`
  position: relative;
  z-index: 2;
  line-height: 1.25;
  font-size: 58px;
  font-weight: 500;
  letter-spacing: 1.8px;
  margin-top: 0;
  margin-bottom: 32px;
  ${rValue("width", ["95%", "85%", "80%", "85%"])}
  ${rValue("font-size", ["38px", "50px", "58px", "78px"])}
  color: ${(props: IStyledTextProps) => {
    return props.color;
  }};

  strong {
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: 1.8px;
    line-height: 0.9;
    color: ${(props: IStyledTextProps) => props.boldWordColor};
  }
`;

export const IntroStyledText = styled(StyledText)`
  padding-top: 2rem;
  font-family: ${FontFamily.Serif};
  ${rValue("font-size", ["22px", "22px", "24px", "26px"])}
  line-height: 1.36;
  ${rValue("width", ["auto", "370px", "455px", "455px"])}
`;
