import { useContext } from "react";
import LanguageContext from "../../../context/LanguageContext";
import { IImageTeaser } from "../../../domain";
import { IContentInset } from "../../../layout";
import { BodyText, ContainerDiv, LabelText, TextContainerDiv, GradientDiv, VisualDiv, InnerVisualDiv } from "./ImageTeaser.styled";
import HeadingSlice from '../../shared/HeadingSlice';

interface IProps {
  teaser: IImageTeaser;
  contentInset: IContentInset;
  sectionColor: string;
  shouldBeVisible: boolean;
}

export default function ImageTeaser(props: IProps) {
  const { language } = useContext(LanguageContext);

  const { teaser, contentInset, shouldBeVisible } = props;

  return (
    <ContainerDiv shouldBeVisible={shouldBeVisible} lang={language} teaser={teaser} className="image-container">
      <VisualDiv>
        <InnerVisualDiv shouldBeVisible={true} lang={language} teaser={teaser}/>
      </VisualDiv>
      <GradientDiv teaser={teaser} />
      {teaser.title && (
        <TextContainerDiv teaser={teaser} contentInset={contentInset} className="textcontainer">
          {teaser.label && (
            <LabelText teaser={teaser} text={teaser.label} size={teaser.labelSize} font={teaser.labelFont} />
          )}
          {teaser.slices && (
            <HeadingSlice content={
              teaser.slices.find((slice) => slice?.type === 'heading')
            }/>
          )}
          {teaser.body && <BodyText teaser={teaser} text={teaser.body} />}
        </TextContainerDiv>
      )}
    </ContainerDiv>
  );
}
