import { useAsync } from "react-async";
import { getOverview } from "../data/overview";
import LoadingScreen from "./shared/LoadingScreen";
import ErrorScreen from "./shared/ErrorScreen";
import ArticlePage from "./ArticlePage";
import OverviewPage from "./OverviewPage";

export default function OverviewData() {
  const { data, error, isPending } = useAsync(getOverview);

  if (isPending) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!data) {
    return <ErrorScreen error={new Error("No overview data")} />;
  }

  return (
    <div>
      <ArticlePage sections={data.sections} />
      <OverviewPage sections={data.sections} />
    </div>
  );
}
