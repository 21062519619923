import styled from "styled-components";
import { FontFamily,  } from "../domain";
import { breakPoints } from "../layout";
import Color from "../colors";

export const ContainerDiv = styled.div`
  display: flex;
  align-items: baseline;

  @media(max-width: ${breakPoints.s}) {
    background: rgba(1, 1, 1, 0.6);
  }
`;

export const LanguageButton = styled.button`
  font-family: ${FontFamily.SharpSansDisplay};
  font-weight: ${(props: { isActive: boolean, textColor: string }) => (props.isActive ? "bold" : "normal")};
  color: ${(props: { isActive: boolean, textColor: string }) => props.textColor};
  text-decoration: none;
  padding: 8px;
  cursor: pointer;
  letter-spacing: 0.04rem;
  position: relative;

  @media(max-width: ${breakPoints.s}) {
    color: ${Color.White};
  }
`;

export const SeparatorDiv = styled.div`
  width: 1px;
  height: 14px;
  background: ${(props: { textColor: string }) => props.textColor};

  @media(max-width: ${breakPoints.s}) {
    display: none;
  }
`;
