import styled, { keyframes } from "styled-components";
import { FontFamily } from "../../domain";
import { ArticleState, twoColumnsWidths } from "./utils";
import { srValue, breakPoints, BreakPoint } from "../../layout";

interface IContainerDivProps {
  articleState: ArticleState;
  windowHeight: number;
}

const containerDivTransform = (props: IContainerDivProps) => {
  switch (props.articleState) {
    case ArticleState.Hidden:
    case ArticleState.AnimatedHide:
      return window.innerHeight;
    case ArticleState.Shown:
    case ArticleState.AnimatedShow:
      return 0;
  }
};

const containerDivKeyFrames = (props: IContainerDivProps) => {
  switch (props.articleState) {
    case ArticleState.Shown:
    case ArticleState.Hidden:
      return "";

    case ArticleState.AnimatedShow:
      return keyframes`
        from { transform: translateY(${props.windowHeight}px); }
        to { transform: translateY(0px); }
      `;

    case ArticleState.AnimatedHide:
      return keyframes`
        from { transform: translateY(0px); }
        to { transform: translateY(${props.windowHeight}px); }
      `;
  }
};

export const ContainerDiv = styled.div`
  position: fixed;
  transform: translateY(${containerDivTransform}px);
  animation: ${(props: IContainerDivProps) => containerDivKeyFrames(props)} 0.5s ease-in-out;
  background: white;
  font-family: ${FontFamily.Serif};
  z-index: 5;
  height: 100vh;
  width: 100vw;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 3;

  hr {
    border-top: 1px solid #D3D3D3;

    &.article-double {
      ${srValue("width", twoColumnsWidths)}
    }
  }
`;

export const SectionBlock = styled.div`
  @media (min-width: ${breakPoints[BreakPoint.S]}) {
    width: 20px;
    height: 140px;
    background-color: ${(props: { color: string }) => props.color};
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
  }
`;

export const ContentContainer = styled.div`
  overflow-y: scroll;
  background-color: white;
`;

export const LanguageSwitchContainer = styled.div`
  position: absolute;
  right: calc(1rem + 20px);
  top: 1rem;
`;
